<template>
  <div class="cpm">
    <page-title title="CPM"></page-title>
    <div class="container">
      <router-link to="/new-report" class="new-report" v-if="campaigns.length && !loading">
        {{ translate.new_report }}
      </router-link>
      <div class="table-wrapper" v-if="campaigns.length && !loading">
        <table class="campaigns">
          <thead class="campaigns-head">
            <tr>
              <th
                class="campaigns-heading"
                v-for="(column, i) in translateColumns"
                :key="i"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody class="campaigns-body">
            <CampaignCard
              v-for="(campaign, i) in campaigns"
              :key="i"
              :campaign="campaign"
              @pdf-download="getCampaignPdfUrl(campaign.id)"
            ></CampaignCard>
          </tbody>
        </table>
      </div>
      <div class="no-campaign" v-else>
        <a-empty>
          <span slot="description" v-if="!loading">{{
            translate.no_campaign
          }}</span>
          <router-link to="/new-report" class="new-report" v-if="!loading">
            {{ translate.new_report }}
          </router-link>
        </a-empty>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/PageTitle";
import CampaignCard from "@/components/elements/CampaignCard";

import { mapState, mapActions } from "vuex";

export default {
  name: "Cpm",
  components: {
    PageTitle,
    CampaignCard,
  },

  computed: {
    ...mapState("Cpm", {
      campaigns: (state) => state.campaigns,
    }),

    ...mapState({
      loading: (state) => state.loading,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
      translateColumns: (state) => {
        const labels = state.labels;

        return {
          name: labels.campaign,
          period: labels.campaign_period,
          cost: labels.campaign_cost,
          impacts: labels.impact,
          cpm: labels.cpm,
          total_panel_spots: labels.total_panel_spots,
          impacts_per_spot: labels.impacts_per_spot,
          total_customer_impact: labels.total_customer_impact,
          campaign_cpm: labels.campaign_cpm,
          report: labels.print_report,
        };
      },
    }),
  },
  methods: {
    ...mapActions("Cpm", ["getCampaigns", "getCampaignPdfUrl"]),
  },
  created() {
    this.getCampaigns();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 15px 0;
  padding: 10px 10px 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: center;

  .new-report {
    align-self: flex-end;
    border: 0;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    background: #36ab5d;
    color: #fff;
    font-weight: var(--text-semibold);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #2ca554;
    }
  }

  .table-wrapper {
    width: 100%;
    overflow-x: auto;
    .campaigns {
      width: 100%;
      border-spacing: 0 10px;
      border-collapse: separate;

      td,
      th {
        text-align: left;
        padding: 0 10px;
        white-space: nowrap;
      }
    }
  }

  .no-campaign {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1580px) {
  .container {
    .table-wrapper {
      overflow: hidden;
    }
  }
}
</style>

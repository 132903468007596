<template>
  <div class="new-report">
    <page-title title="CPM"></page-title>
    <form class="container" @submit.prevent enctype="multipart/form-data">
      <div class="form-inputs">
        <div class="form-item">
          <label for="campaign-name">{{ translate.campaign_name }}*</label>
          <a-input id="campaign-name" required v-model="name" />
        </div>
        <div class="form-item">
          <label for="file">{{ translate.campaign_photo }}</label>
          <label for="file" class="upload-image">
            <a-tooltip class="upload-btn" placement="topLeft">
              <template slot="title">
                <span>{{ translate.campaign_photo }}</span>
              </template>
              <a-icon
                type="file-image"
                theme="twoTone"
                two-tone-color="#2C82C9"
              />
              <input
                class="upload-file"
                id="file"
                type="file"
                name="file"
                ref="file"
                @change="handleFileUpload"
              />
            </a-tooltip>
          </label>
        </div>
        <div class="form-item">
          <RangeFilter :title="translate.campaign_period + '*'" />
        </div>
        <div class="form-item">
          <label for="campaign-cost">{{ translate.campaign_cost }}*</label>
          <span class="currency" :class="currency === 'R$' ? 'real' : ''">{{
            currency
          }}</span>
          <imask-input
            class="cost-input"
            v-model="cost"
            :mask="Number"
            thousands-separator="."
            radix=","
            :unmask="true"
            placeholder="0,00"
          />
        </div>
        <div class="form-item">
          <label>{{ translate.calculate_based_on }}:</label>
          <a-radio-group v-model="impacts" class="radios">
            <a-radio value="impacts" @click="resetWeightedFilters">
              {{ translate.impact }}
            </a-radio>
            <a-radio value="weighted-impacts">
              {{ translate.weighted_impacts }}
            </a-radio>
          </a-radio-group>
        </div>
        <button type="button" class="save-btn" @click="saveReport">
          {{ translate.generate }}
        </button>
      </div>
      <div class="panel-settings" v-if="impacts == 'weighted-impacts'">
        <CampaignSettingsForm />
      </div>
      <div class="form-item spots-form">
        <a-checkbox @change="handleDigitalToggle">
          Digital (DOOH - Digital Out-of-Home)
        </a-checkbox>
        <div class="spots-inputs">
          <SpotInput
            v-if="isDigital"
            :spotLabel="translate.spots_per_day"
            :spotTooltip="translate.total_spots_per_day"
            v-model="dailySpots"
          />
          <SpotInput
            v-if="isDigital"
            :spotLabel="translate.client_spots_day"
            :spotTooltip="translate.customer_spots"
            v-model="dailyClientSpots"
          />
        </div>
      </div>
      <div class="panels">
        <div class="no-panel-alert" v-if="noPanelSelected">
          <a-alert
            :message="translate.select_one_panel"
            type="warning"
            show-icon
          />
        </div>
        <a-table
          :pagination="false"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: handleToggleCheck,
            hideDefaultSelections: true,
            selections: false,
          }"
          :columns="columnsTranslate"
          :data-source="panels"
        ></a-table>
      </div>
    </form>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/PageTitle";
import RangeFilter from "@/components/elements/RangeFilter";
import CampaignSettingsForm from "@/components/elements/CampaignSettingsForm";
import SpotInput from "@/components/elements/SpotInput";

import { mapState, mapActions, mapMutations } from "vuex";

import { IMaskComponent } from "vue-imask";

let columns = [];

export default {
  name: "NewReport",

  components: {
    PageTitle,
    RangeFilter,
    CampaignSettingsForm,
    SpotInput,
    "imask-input": IMaskComponent,
  },

  data() {
    return {
      name: null,
      cost: null,
      image: null,
      impacts: "impacts",
      panels: [],
      columns,
      selectedRowKeys: [],
      selectedRows: [],
      noPanelSelected: false,
      isDigital: false,
      dailySpots: null,
      dailyClientSpots: null,
    };
  },

  watch: {
    panelsList(data) {
      let panels = [];

      for (let i = 0; i < data.length; i++) {
        panels.push({
          key: data[i].id,
          identification: data[i].description,
        });
      }

      this.panels = panels;
    },

    selectedRows() {
      if (!this.selectedRows.length) {
        this.noPanelSelected = true;
      } else {
        this.noPanelSelected = false;
      }
    },
  },

  computed: {
    currency() {
      return this.client.language === "PT" ? "R$" : "$";
    },

    ...mapState("Cpm", {
      campaign: (state) => state.campaign,
    }),

    ...mapState("PanelSelectFilter", {
      panelsList: (state) => state.panelsList,
    }),

    ...mapState("Translate", {
      translate: (state) => state.labels,
      columnsTranslate: (state) => {
        const labels = state.labels;

        return (columns = [
          {
            title: labels.panel,
            dataIndex: "identification",
            key: "identification",
            width: "100%",
          },
        ]);
      },
    }),

    ...mapState("User", {
      client: (state) => state.client,
    }),
  },

  methods: {
    handleFileUpload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    saveReport() {
      if (this.checkFields()) {
        this.saveCampaign({
          name: this.name,
          cost: this.cost,
          image: this.image,
          panels: this.selectedRows.map((panel) => panel.key).toString(),
          dailySpots: this.dailySpots,
          dailyClientSpots: this.dailyClientSpots,
        });
        this.resetWeightedFilters();
      }
    },

    handleToggleCheck(selectedRowKeys, selectedRows) {
      this.setSelectedPanels(selectedRowKeys);

      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    resetWeightedFilters() {
      this.saveCampaignSetting({});
    },

    checkFields() {
      if (!this.selectedRows.length) {
        this.noPanelSelected = true;
      } else {
        this.noPanelSelected = false;
      }
      if (this.name && this.cost && this.selectedRows.length) return true;
    },

    handleDigitalToggle() {
      this.isDigital = !this.isDigital;
      this.dailySpots = null;
      this.dailyClientSpots = null;
    },

    ...mapActions("PanelSelectFilter", ["getPanels"]),

    ...mapActions("Cpm", ["saveCampaign", "saveCampaignSetting"]),

    ...mapMutations("PdfExport", {
      setSelectedPanels: "SET_SELECTED_PANELS",
    }),
  },

  created() {
    this.getPanels();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0;
  padding: 10px 10px 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  .form-inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    max-width: 280px;
    position: relative;
    margin-left: 15px;

    label {
      font-weight: var(--text-semibold);
      color: var(--gray-80);
    }

    .ant-radio-wrapper {
      font-weight: var(--text-regular);
    }

    .upload-image {
      width: 32px;
      height: 32px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0px 2px rgba(0, 0, 0, 0.3);
      background: #fff;

      .upload-btn {
        font-size: 24px;
      }

      &:hover {
        transform: scale(1.04);
        background: #fafafa;
      }
    }
    .upload-file {
      background: #fff;
      outline: none;
      max-width: 190px;
      display: none;
    }

    .radios {
      margin-top: 10px;
    }

    .cost-input {
      border: 1px solid #d9d9d9;
      height: 32px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 1.5;
      background-color: #fff;
      border-radius: 4px;
      transition: all 0.3s;

      &:hover {
        border-color: #40a9ff;
        border-right-width: 1px !important;
      }

      &:focus,
      &:active {
        border-color: #40a9ff;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.2);
      }

      &::placeholder {
        color: #b9b9b9;
      }
    }

    .currency {
      position: absolute;
      left: -12px;
      bottom: 5px;
      font-size: 15px;
    }

    .real {
      left: -22px;
    }
  }

  .spots-form {
    max-width: 340px;
    margin-top: 1rem;

    .spots-inputs {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;
    }
  }

  .save-btn {
    border: 0;
    border-radius: 4px;
    padding: 10px;
    background: #36ab5d;
    color: #fff;
    font-weight: var(--text-semibold);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #2ca554;
    }
  }

  .panel-settings {
    align-self: flex-end;
    margin-top: 20px;
  }

  .panels {
    margin-top: 35px;

    .no-panel-alert {
      width: 260px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 680px) {
  .container {
    .form-inputs {
      .form-item {
        margin-left: 0;
      }
    }
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

// Auth Pages
import Login from "../views/AuthPages/Login.vue";
import RecoveryPassword from "../views/AuthPages/RecoveryPassword.vue";
import ResetPassword from "../views/AuthPages/ResetPassword.vue";
import AuthRouterView from "../views/AuthPages/AuthRouterView.vue";

// Base Pages
import Unauthorized from "../views/BasePages/Unauthorized.vue";
import NotFound from "../views/BasePages/NotFound.vue";
import Layout from "../views/BasePages/Layout.vue";
import Account from "../views/BasePages/Account.vue";

// Pages
import Home from "../views/Home.vue";
import Impacts from "../views/Impacts.vue";
import Statistics from "../views/Statistics.vue";
import Comparative from "../views/Comparative.vue";
import Export from "../views/Export.vue";
import Monitoring from "../views/Monitoring.vue";
import Cpm from "../views/Cpm.vue";
import NewReport from "../views/NewReport.vue";
//import UserInfo from "../views/UserInfo.vue";
import MyPanels from "../views/MyPanels.vue";
import Payment from "../views/Payment.vue";

import { getToken } from "../utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
      },
      {
        path: "impacts",
        name: "Impacts",
        component: Impacts,
      },
      {
        path: "statistics",
        name: "Statistics",
        component: Statistics,
      },
      {
        path: "comparative",
        name: "Comparative",
        component: Comparative,
      },
      {
        path: "export",
        name: "Export",
        component: Export,
      },
      {
        path: "monitoring",
        name: "Monitoring",
        component: Monitoring,
      },
      {
        path: "cpm",
        name: "Cpm",
        component: Cpm,
      },
      {
        path: "new-report",
        name: "NewReport",
        component: NewReport,
      },
    ],
  },
  {
    path: "/account",
    component: Account,
    redirect: "/account/my-panels",
    children: [
      /*{
        path: "my-data",
        name: "UserInfo",
        component: UserInfo,
      },*/
      {
        path: "my-panels",
        name: "MyPanels",
        component: MyPanels,
      },
      {
        path: "payment",
        name: "Payment",
        component: Payment,
      },
    ],
  },
  {
    path: "/",
    component: AuthRouterView,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "recuperar-senha",
        name: "Recovery Password",
        component: RecoveryPassword,
      },
      {
        path: "resetar-senha",
        name: "Reset Password",
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/nao-autorizado",
    name: "Unauthorized",
    component: Unauthorized,
  },
  {
    path: "/nao-encontrado",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/nao-encontrado",
    "/nao-autorizado",
    "/resetar-senha",
    "/recuperar-senha",
  ];
  const authRequired = !publicPages.includes(to.path);
  const logged = getToken();

  if (authRequired && !logged) return next("/login");

  next();
});

export default router;

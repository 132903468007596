<template>
  <a-menu
    theme="light"
    mode="horizontal"
    :default-selected-keys="[$route.fullPath]"
  >
    <a-menu-item key="/home">
      <router-link to="/">
        <a-icon type="home" />
        <span>{{ translate.home }}</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="/impacts">
      <router-link to="impacts">
        <a-icon type="pie-chart" />
        <span>{{ translate.impact }}</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="/statistics">
      <router-link to="statistics">
        <a-icon type="line-chart" />
        <span>{{ translate.statistics }}</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="/comparative" v-if="dashComplete">
      <router-link to="comparative">
        <a-icon type="bars" />
        <span>{{ translate.comparative }}</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="export" v-if="dashComplete">
      <router-link to="export">
        <a-icon type="export" />
        <span>{{ translate.export }}</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="/cpm" v-if="dashComplete">
      <router-link to="cpm">
        <a-icon type="monitor" />
        <span>CPM</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="/monitoring" v-if="dashComplete">
      <router-link to="monitoring">
        <a-icon type="dashboard" />
        <span>{{ translate.monitoring }}</span>
      </router-link>
    </a-menu-item>
  </a-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
    ...mapState("User", {
      dashComplete: (state) => state.dashComplete,
    }),
  },
};
</script>
<template>
  <tr class="campaign-card">
    <td class="campaign-item">{{ campaign.name }}</td>
    <td class="campaign-item">{{ campaign.period }}</td>
    <td class="campaign-item">{{ campaign.value }}</td>
    <td class="campaign-item">{{ campaign.impacts }}</td>
    <td class="campaign-item">{{ campaign.cpm_result }}</td>
    <td class="campaign-item">{{ campaign.total_panel_spots || "-" }}</td>
    <td class="campaign-item">{{ campaign.impacts_per_spot || "-" }}</td>
    <td class="campaign-item">{{ campaign.total_client_impact || "-" }}</td>
    <td class="campaign-item">{{ campaign.campaign_cpm || "-" }}</td>
    <td class="campaign-item">
      <PdfIconButton
        :title="translate.print_report"
        @click="handlePdfDownload"
      ></PdfIconButton>
    </td>
  </tr>
</template>

<script>
import PdfIconButton from "@/components/elements/PdfIconButton";
import { mapState } from "vuex";

export default {
  name: "CampaignCard",
  components: {
    PdfIconButton,
  },

  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
  },

  methods: {
    handlePdfDownload() {
      this.$emit("pdf-download");
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-card {
  width: 100%;
  gap: 2rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);

  .campaign-item {
    padding: 15px 10px;
    white-space: nowrap;
  }
}
</style>

import store from "../../index";
import router from "@/router";

import { campaigns, saveCampaign, pdfCampaign } from "../../../api/cpm.api";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/error-notifications";

export const Cpm = {
  state: {
    campaigns: [],
    campaign: {},
    setting: {},
  },
  mutations: {
    SET_CAMPAIGNS(state, campaigns) {
      state.campaigns = campaigns;
    },

    SET_CAMPAIGN(state, campaign) {
      const filters = {
        ...store.getters["RangeFilter/filter"],
      };
      state.campaign = campaign;
      campaign.period = filters;
      campaign.setting = state.setting;
    },

    SET_SETTING(state, setting) {
      state.setting = setting;
    },
  },
  actions: {
    async getCampaigns({ commit }) {
      commit("SET_LOADING", true, { root: true });
      try {
        const {
          data: { body },
        } = await campaigns();
        commit("SET_CAMPAIGNS", body.campaigns);
      } catch (error) {
        showErrorNotification(error);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async saveCampaign({ commit }, campaign) {
      commit("SET_LOADING", true, { root: true });

      try {
        commit("SET_CAMPAIGN", campaign);
        const { data } = await saveCampaign({ campaign });
        showSuccessNotification(data.title);
        router.replace("/cpm");
      } catch (error) {
        showErrorNotification(error);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async saveCampaignSetting({ commit }, setting) {
      commit("SET_LOADING", true, { root: true });

      try {
        commit("SET_SETTING", setting);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getCampaignPdfUrl({ commit }, campaignId) {
      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await pdfCampaign(campaignId);
        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },
  },
  namespaced: true,
};


export const RangeFilter = {
  state: {
    start: null,
    end: null,
  },
  mutations: {
    SET_DATE_INTERVAL(state, { startDate, endDate }) {
      state.start = startDate;
      state.end = endDate;
    },
  },
  getters: {
    filter: (state) => {
      return {
        startDate: state.start,
        endDate: state.end,
      };
    },
  },
  namespaced: true,
};

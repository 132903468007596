import router from "@/router";

import {
  authenticateUser,
  recoverPassword,
  newPassword,
  userEmail,
} from "@/api/auth.api";

import { notification } from "ant-design-vue";

export const Auth = {
  state: {
    userAuth: {
      token: "",
      expiresIn: 0,
      tokenType: "",
    },
    email: "",
  },
  mutations: {
    SET_USER_AUTH_DATA(state, { access_token, expires_in, token_type }) {
      state.userAuth.token = access_token;
      state.userAuth.expiresIn = expires_in;
      state.userAuth.tokenType = token_type;
    },
  },
  actions: {
    async login({ dispatch, commit, state }, payload) {
      commit("SET_LOADING", true, { root: true });
      try {
        const { data } = await authenticateUser(payload);
        state.email = payload.email;
        if (data.access_token)
          localStorage.setItem("user", JSON.stringify(data));
        commit("SET_USER_AUTH_DATA", data);
        router.push("/");
      } catch (error) {
        dispatch(
          "Common/setNotification",
          {
            type: "error",
            message:
              "Erro ao autenticar usuário. Verifique o email e a senha preenchidos.",
          },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async recoverPassword({ dispatch, commit }, { email }) {
      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await recoverPassword({ email });
        notification.success({
          message: "Sucesso!",
          description: data?.message,
        });
        router.push("/resetar-senha");
      } catch ({ response: { data } }) {
        dispatch(
          "Common/setNotification",
          {
            type: "error",
            message: data?.message,
          },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async resetPassword({ dispatch, commit }, payload) {
      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await newPassword(payload);
        notification.success({
          message: "Sucesso!",
          description: data?.message,
        });
        router.push("/login");
      } catch ({ response: { data } }) {
        dispatch(
          "Common/setNotification",
          {
            type: "error",
            message: data?.message,
          },
          { root: true }
        );
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async logout({ state }) {
      try {
        const { data } = await userEmail({ email: state.email });
        console.log(data);
      } catch (e) {
        console.log(e);
      } finally {
        sessionStorage.clear();
        localStorage.removeItem("user");
        router.push("/login");
        window.location.reload(true);
      }
    },
  },
  getters: {},
  namespaced: true,
};

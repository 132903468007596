import store from "../../index";

import { 
  barChart,
  lineChart,
  weightedChartPerDay,
  weightedChartPerHour,
  weightedChartXls,
  dashboardChartXls,
  dashboardChartPdf,
  uploadPdfCustomImage,
} from "../../../api/graphs.api";

import { showSuccessNotification, showErrorNotification } from "../../../utils/error-notifications";

export const Graph = {
  state: {
    panel: {},
    cards: {},
    lineChart: {},
    barChart: {},
    progress: {},
    weightedChartPerDay: {},
    weightedChartPerHour: {},
    weightedCards: {},
    periodInfoLineChart: {},
    periodInfoBarChart: {},
    periodInfoWeightedChart: {},
  },
  mutations: {
    SET_CARDS(state, cards) {
      state.cards = cards;
    },
    SET_LINE_CHART(state, data) {
      state.lineChart = data;
    },
    SET_BAR_CHART(state, data) {
      state.barChart = data;
    },
    SET_PROGRESS(state, data) {
      state.progress = data;
    },
    SET_WEIGHTED_CHART_DAY(state, data) {
      state.weightedChartPerDay = data;
    },
    SET_WEIGHTED_CHART_HOUR(state, data) {
      state.weightedChartPerHour = data;
    },
    SET_WEIGHTED_CARDS(state, data) {
      state.weightedCards = data;
    },
    SET_PERIOD_INFO_LINE_CHART(state, data) {
      state.periodInfoLineChart = data
    },
    SET_PERIOD_INFO_BAR_CHART(state, data) {
      state.periodInfoBarChart = data
    },
    SET_PERIOD_INFO_WEIGHTED_CHART(state, data) {
      state.periodInfoWeightedChart = data
    },
  },
  actions: {
    async getLineChart({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
        lineFilter: store.getters["User/getActiveLineFilter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await lineChart(filters);

        commit("SET_CARDS", body.cards);
        commit("SET_LINE_CHART", body.chartdata);
        commit("SET_PERIOD_INFO_LINE_CHART", body.period_date_labels);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getBarChart({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
        classFilter: store.getters["User/getActiveClassFilter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await barChart(filters);

        commit("SET_PROGRESS", body.cards);
        commit("SET_BAR_CHART", body.chartdata);
        commit("SET_PERIOD_INFO_BAR_CHART", body.period_date_labels);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getWeightedChartPerDay({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"]
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await weightedChartPerDay(filters);

        commit("SET_WEIGHTED_CHART_DAY", body.chartdata);
        commit("SET_WEIGHTED_CARDS", body.cards);
        commit("SET_PERIOD_INFO_WEIGHTED_CHART", body.period_date_labels);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getWeightedChartPerHour({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const {
          data: { body },
        } = await weightedChartPerHour(filters);

        commit("SET_WEIGHTED_CHART_HOUR", body.chartdata);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getWeightedChartXls({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await weightedChartXls(filters);

        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getDashboardChartXls({ commit }) {
      const filters = {
        panel: store.getters["PanelSelectFilter/activePanelId"],
        ...store.getters["RangeFilter/filter"],
      };

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await dashboardChartXls(filters);

        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async getDashboardChartPdf({ commit }, payload) {
      const panelId = store.getters["PanelSelectFilter/activePanelId"];

      commit("SET_LOADING", true, { root: true });

      try {
        const { data } = await dashboardChartPdf({ panelId, payload });

        if (data.success) window.location.assign(data.body.download_url);
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    },

    async uploadPdfReportImage({ commit }, file) {
      commit("SET_LOADING", true, { root: true });

      const panelId = store.getters["PanelSelectFilter/activePanelId"];
      const formData = new FormData();
      formData.append('image', file, file.name);

      try {
        const { data } = await uploadPdfCustomImage({ panelId, formData });

        if (!data.success) throw 'Erro ao executar o upload da imagem.';

        showSuccessNotification("Imagem atualizada com sucesso!");
      } catch (e) {
        showErrorNotification(e);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    }
  },
  getters: {},
  namespaced: true,
};

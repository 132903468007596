<template>
  <div class="home">
    <page-title title="Home"></page-title>

    <div class="panel-container">
      <PanelSelectFilter
        class="panel-select-filter"
        :title="translate.panel"
        :label="translate.address"
        @change="buildCharts"
      />

      <span class="panel-disabled" v-if="panel.active === 2"
        >disabled</span
      >

      <RangeFilter class="range-filter" :title="translate.period" @afterSetFilter="buildCharts" />
      <div class="btns-wrapper">
        <a-skeleton
          :loading="true"
          active
          :paragraph="{ rows: 1, width: 80 }"
          v-if="loading"
        />
        <div class="download-icons" v-if="!loading">
          <PdfIconButton
            :title="translate.pdf_export"
            @click="handleDownloadPdf"
          />
          <XlsIconButton
            :title="translate.xls_export"
            @click="getDashboardChartXls"
          />
          <UploadImageButton
            class="upload-image"
            :title="translate.upload_image"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="line-graph-wrapper">
        <div class="period">
          <a-skeleton
            active
            :paragraph="{ rows: 1, width: 1 }"
            v-if="loading"
          />
          <p
            class="graph-title period-title"
            v-if="intervalLabelLineChart && !loading"
          >
            {{ intervalLabelLineChart }}
          </p>
        </div>
        <div class="card-group">
          <statistic-card
            :title="translate.total"
            :value="cards.total"
            hasTooltipTotal
          />
          <statistic-card
            :title="translate.person"
            type="pedestres"
            :value="cards.pedestres"
            hasTooltipPedestres
          />
          <statistic-card
            :title="translate.vehicles"
            type="veiculos"
            :value="cards.veiculos"
            hasTooltipVeiculos
          />
        </div>
        <div class="graph">
          <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
          <div class="graph-item" v-if="!loading">
            <p class="graph-title">{{ translate.hourly_flow }}</p>
            <line-graph
              :chartData="lineChart"
              v-if="
                lineChart.labels &&
                lineChart.datasets &&
                lineChart.labels.length &&
                lineChart.datasets.length
              "
            ></line-graph>
            <p
              style="text-align: center"
              v-if="
                lineChart.labels &&
                lineChart.datasets &&
                !lineChart.labels.length &&
                !lineChart.datasets.length
              "
            >
              {{ translate.no_data }}
            </p>
          </div>
        </div>
      </div>
      <div class="bar-graph-wrapper">
        <div class="period">
          <a-skeleton
            active
            :paragraph="{ rows: 1, width: 1 }"
            v-if="loading"
          />
          <p
            class="graph-title period-title"
            v-if="intervalLabelBarChart && !loading"
          >
            {{ intervalLabelBarChart }}
          </p>
        </div>
        <div class="progress-group">
          <progress-circle
            :label="translate.total"
            :value="formatNumber(progress.total)"
            :percent="calcPercentTotal(progress, progress.total)"
            color="#4bab68"
          />
          <progress-circle
            :label="translate.person"
            type="pessoas"
            :value="formatNumber(progress.pedestres)"
            :percent="calcPercent(progress, progress.pedestres)"
            color="#A5DFDF"
          />
          <progress-circle
            :label="translate.bicycle"
            type="bicicletas"
            :value="formatNumber(progress.bicicletas)"
            :percent="calcPercent(progress, progress.bicicletas)"
            color="#A8DGDF"
          />
          <progress-circle
            :label="translate.car"
            type="carros"
            :value="formatNumber(progress.carros)"
            :percent="calcPercent(progress, progress.carros)"
            color="#FFB1C1"
          />
          <progress-circle
            :label="translate.bus"
            type="onibus"
            :value="formatNumber(progress.onibus)"
            :percent="calcPercent(progress, progress.onibus)"
            color="#fd4848"
          />
          <progress-circle
            :label="translate.motorcycle"
            type="motos"
            :value="formatNumber(progress.motos)"
            :percent="calcPercent(progress, progress.motos)"
            color="#eaa807"
          />
          <progress-circle
            :label="translate.truck"
            type="caminhoes"
            :value="formatNumber(progress.caminhoes)"
            :percent="calcPercent(progress, progress.caminhoes)"
            color="#5d52ff"
          />
        </div>
        <div class="graph">
          <a-skeleton active :paragraph="{ rows: 4 }" v-if="loading" />
          <div class="graph-item" v-if="!loading">
            <p class="graph-title">{{ translate.daily_flow }}</p>
            <bar-graph
              :chartData="barChart"
              v-if="
                barChart.labels &&
                barChart.datasets &&
                barChart.labels.length &&
                barChart.datasets.length
              "
            ></bar-graph>
            <p
              style="text-align: center"
              v-if="
                barChart.labels &&
                barChart.datasets &&
                !barChart.labels.length &&
                !barChart.datasets.length
              "
            >
              {{ translate.no_data }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCircle from "../components/elements/ProgressCircle.vue";
import StatisticCard from "../components/elements/StatisticCard.vue";
import LineGraph from "../components/modules/Graphs/LineGraph.vue";
import BarGraph from "../components/modules/Graphs/BarGraph.vue";
import PageTitle from "@/components/elements/PageTitle.vue";
import RangeFilter from "@/components/elements/RangeFilter";
import PanelSelectFilter from "@/components/elements/PanelSelectFilter";
import PdfIconButton from "@/components/elements/PdfIconButton";
import XlsIconButton from "@/components/elements/XlsIconButton";
import UploadImageButton from "@/components/elements/UploadImageButton";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    StatisticCard,
    ProgressCircle,
    LineGraph,
    BarGraph,
    PageTitle,
    RangeFilter,
    PanelSelectFilter,
    PdfIconButton,
    XlsIconButton,
    UploadImageButton,
  },
  name: "BaseLayout",
  computed: {
    ...mapState("Graph", {
      cards: (state) => state.cards,
      progress: (state) => state.progress,
      lineChart: (state) => state.lineChart,
      barChart: (state) => state.barChart,
      intervalLabelLineChart: (state) => {
        const label = state.periodInfoLineChart;

        return label.inicial ? `${label.inicial} ~ ${label.final}` : "";
      },
      intervalLabelBarChart: (state) => {
        const label = state.periodInfoBarChart;

        return label.inicial ? `${label.inicial} ~ ${label.final}` : "";
      },
    }),
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),

    ...mapState("PanelSelectFilter", {
      panel: (state) => {
        const value = state.activePanelId;

        const panel =
          value > 0
            ? state.panelsList.filter(({ id }) => id === value)[0]
            : state.panelsList[0];

        return {
          id: panel?.id,
          address: panel?.address,
          active: panel?.active,
        };
      },
    }),
  },
  methods: {
    ...mapActions("Graph", [
      "getLineChart",
      "getBarChart",
      "getDashboardChartPdf",
      "getDashboardChartXls",
    ]),

    calcPercent(allItems, givenItem) {
      if (!allItems || !givenItem) return;
      const values = Object.values(allItems);
      const total = values.reduce((total, value) => total + value);
      const percentage = (100 * givenItem) / total;
      return percentage;
    },
    calcPercentTotal(allItems, givenItem) {
      if (!allItems || !givenItem) return;
      const values = Object.values(allItems);
      const total = values.reduce((total, value) => total + value);
      return total;
    },
    formatNumber(num) {
      const n = String(num),
        p = n.indexOf(".");
      return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
        p < 0 || i < p ? `${m}.` : m
      );
    },

    buildCharts() {
      this.getLineChart();
      this.getBarChart();
    },

    handleDownloadPdf() {
      const lineChart = document.getElementById("line-chart");
      const barChart = document.getElementById("bar-chart");
      const urlLogCustomer = document.querySelector(".customer-logo");

      this.getDashboardChartPdf({
        path: "home",
        dataURLLineChart: lineChart.toDataURL(),
        dataURLBarChart: barChart.toDataURL(),
        urlLogCustomer: urlLogCustomer.getAttribute("src"),
        addressPanelCustomer: this.panel.address,
        cardsLineChart: {
          periodo: `${this.translate.period} ${this.intervalLabelLineChart}`,
          total: this.cards.total,
          pedestres: this.cards.pedestres,
          veiculos: this.cards.veiculos,
        },
        progressBarChart: {
          periodo: `${this.translate.period} ${this.intervalLabelBarChart}`,
          total: this.progress.total,
          carros: this.progress.carros,
          onibus: this.progress.onibus,
          motos: this.progress.motos,
          caminhoes: this.progress.caminhoes,
          bicicletas: this.progress.bicicletas,
        },
      });
    },
  },
  created() {
    this.buildCharts();
  },
};
</script>

<style lang="scss" scoped>
.panel-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 15px 0;
  padding: 10px 10px 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  .panel-disabled {
    background: #fff1f0;
    color: #f5222d;
    border: 1px solid #ffa39e;
    padding: 2px 10px;
    border-radius: 4px;
    max-height: 30px;
    align-self: center;
  }

  .btns-wrapper {
    margin-top: 1.5rem;
    .download-icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .line-graph-wrapper,
  .bar-graph-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 15px;
    padding-top: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }
}

.card-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 0 20px;
}

.progress-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0 20px;
}

.graph {
  max-width: 100%;
  display: grid;
  padding: 20px;
  gap: 20px;

  .graph-item {
    max-width: 100%;
  }

  & .graph-title {
    font-weight: var(--text-bold);
    text-align: center;
    font-size: var(--text-18);
  }
}

.period-title {
  font-weight: bold;
  text-align: center;
  font-size: var(--text-18);
}

@media screen and (min-width: 460px) {
  .panel-disabled {
    margin-top: 20px;
  }
  .card-group {
    grid-template-columns: repeat(1, 1fr);
  }

  .container {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .panel-disabled {
    margin-bottom: 5px;
    margin-top: 0;
  }

  .card-group {
    grid-template-columns: repeat(3, 1fr);
  }

  .progress-group {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 930px) {
  .progress-group {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (min-width: 1340px) {
  .panel-container {
    padding-bottom: 0;
  }

  .container {
    flex-direction: row;

    .line-graph-wrapper {
      margin-right: 7.5px;
    }

    .bar-graph-wrapper {
      margin-left: 7.5px;
    }

    .period {
      .period-title {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .panel-container {
    justify-content: space-between;

    .download-icons {
      margin-bottom: 8px;
    }
  }
}

@media screen and (min-width: 1640px) {
  .container {
    .line-graph-wrapper {
      margin-right: 7.5px;
    }

    .bar-graph-wrapper {
      margin-left: 7.5px;
    }

    .period {
      .period-title {
        margin-bottom: 20px;
      }
    }

    .card-group {
      margin-bottom: 0;
    }

    .progress-group {
      padding-left: 12px;
    }
  }
}
</style>
